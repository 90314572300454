<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">MONTHLY COLLECTION GGC LOANS</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-layout row wrap class="align-center mx-2 mt-4">
        <v-flex xs12 md4>
          <v-select
            outlined
            v-model="month_of"
            class="mx-2"
            :items="month_of_items"
            item-value="month_of"
            item-text="month_of"
            label="Month Of"
            required
            :rules="rules.combobox_rule"
            dense
            @change="search_data"
          ></v-select>
        </v-flex>
      </v-layout>
      <v-layout row wrap class="align-center mx-2">
        <v-flex xs12 md4>
          <v-select
            class="mx-2"
            v-model="type_of_loan"
            :items="['All','Tie-Up','Damayan','Business']"
            label="Loan Type"
            required
            :rules="rules.combobox_rule"
            dense
            outlined
            @change="search_data"
          ></v-select>
        </v-flex>
      </v-layout>
      <v-data-table dense
                    class="mt-3"
                    :headers="headers"
                    :items="data"
                    disable-pagination
                    hide-default-footer
                    :search="search"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <h2>Print|
              <v-icon class="mr-2" color="success" @click="print_data"
                      v-if="!printt">
                {{ icons.mdiPrinter }}
              </v-icon>
              <v-progress-circular :size="25" :width="5" color="success" indeterminate
                                   v-if="printt"></v-progress-circular>
            </h2>
            <v-spacer></v-spacer>
            <v-text-field
              class="mx-2"
              :append-icon="icons.mdiAccountSearchOutline"
              label="Search"
              single-line
              hide-details
              v-model="search"
            ></v-text-field>
          </v-toolbar>
        </template>
        <template v-slot:item="{ item }">
          <tr>
            <td>
              {{ item.deposit_slip.date_of_deposit }}
            </td>
            <td>
              {{ item.deposit_slip.bank_depositories.bank_code }}
            </td>
            <td>
              {{ item.due_date }}
            </td>
            <td>
              {{ item.loan_data.name }}
            </td>
            <td>
              {{ item.or_date }}
            </td>
            <td>
              {{ item.or_no }}
            </td>
            <td>
              {{ formatPrice(item.amount) }}
            </td>
            <td>
              {{ formatPrice(item.interest) }}
            </td>
            <td>
              {{ formatPrice(item.penalty) }}
            </td>
            <td>
              {{
              formatPrice(parseFloat(item.amount)+parseFloat(item.interest)+parseFloat(item.penalty))
              }}
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
    <snack-bar-dialog :snackbar_flag="this.snackbar" :color="this.snackbar_color"
                      :snackbar_text="this.snackbar_text"/>
  </div>
</template>

<script>
  import {
    mdiCheck, mdiClose, mdiPlusBox
    , mdiPencil
    , mdiAccountSearchOutline
    , mdiAccountClock
    , mdiPrinter
  } from '@mdi/js'
  import moment from 'moment'
  import {mapActions, mapGetters} from 'vuex'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
  import {P} from 'caniuse-lite/data/agents'

  const initialState = () => {
    return {
      printt: false,
      search: '',
      month_of: '',
      type_of_loan: '',
      month_of_items: [],
      data: [],
      headers: [
        {text: 'Deposit Date', value: 'due_date', sortable: true},
        {text: 'Deposit Bank', value: 'due_date', sortable: true},
        {text: 'Due Date', value: 'due_date', sortable: true},
        {text: 'Name', value: 'loan_data.name', sortable: true},
        {text: 'OR Date', value: 'or_date', sortable: true},
        {text: 'OR #', value: 'or_no', sortable: true},
        {text: 'Amount', value: 'amount', sortable: true},
        {text: 'Interest', value: 'interest', sortable: true},
        {text: 'Penalty', value: 'penalty', sortable: true},
        {text: 'Total', value: 'amount', sortable: false},
      ],
    }
  }
  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiCheck,
          mdiClose,
          mdiPlusBox,
          mdiPencil,
          mdiAccountSearchOutline,
          mdiAccountClock,
          mdiPrinter,
        },
      }
    },
    data() {
      return initialState()
    },
    mounted() {
      this.initialize_salaries_employee()
        .then(response => {
          this.month_of_items = response.data[0].month_of
        })
        .catch(error => {
          console.log(error)
        })
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['address', 'contact']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo', 'trea']),
      ...mapGetters('system_data', [
        'snackbar',
        'snackbar_color',
        'snackbar_text',
        'company_logo',
        'damayan',
        'chapel',
        'factory',
        'coop',
        'printing',
        'accounting',
        'admin',
        'amd',
        'audit',
        'book',
        'creative',
        'ecut',
        'hr',
        'it',
        'prot',
        'trea',
      ]),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('salaries_and_wages', ['initialize_salaries_employee']),
      ...mapActions('ggc_loans', ['monthly_collection_ggc_loan']),
      search_data() {
        this.monthly_collection_ggc_loan({
          month_of: this.month_of,
          type_of_loan: this.type_of_loan,
        })
          .then(response => {
            this.data = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      date_format(value) {
        return moment(value)
      },
      get_balance(value) {
        var paid = 0;
        value.payment_paid.forEach(function (item) {
          paid += (parseFloat(item.amount) + parseFloat(item.interest))
        })
        return parseFloat(value.total_loan) - parseFloat(paid)
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      async print_data() {
        this.printt = true
        var imgData = this.company_logo

        var payments_array = []
        var widths = []
        var damayan_ = this.damayan
        var chapel_ = this.chapel
        var factory_ = this.factory
        var coop_ = this.coop
        var printing_ = this.printing
        var info_array = []

        widths = [50, 70, 50, 150, 50, 50, 50, 50, 50, 50]
        payments_array.push([
          {text: 'Deposit Date', alignment: 'left', style: 'main_info'},
          {text: 'Deposit Bank', alignment: 'left', style: 'main_info'},
          {text: 'Due Date', alignment: 'left', style: 'main_info'},
          {text: 'Name', alignment: 'left', style: 'main_info'},
          {text: 'OR Date', alignment: 'left', style: 'main_info'},
          {text: 'OR #', alignment: 'left', style: 'main_info'},
          {text: 'Amount', alignment: 'left', style: 'main_info'},
          {text: 'Interest', alignment: 'left', style: 'main_info'},
          {text: 'Penalty', alignment: 'left', style: 'main_info'},
          {text: 'Total', alignment: 'left', style: 'main_info'},
        ])
        var total_amm = 0
        var total_intt = 0
        var total_penal = 0
        var grand_total = 0
        this.data.forEach(function (item, index, payment) {
          total_amm += parseFloat(item.amount)
          total_intt += parseFloat(item.interest)
          total_penal += parseFloat(item.penalty)
          grand_total += parseFloat(item.amount) + parseFloat(item.interest) + parseFloat(item.penalty)
          payments_array.push([
            {
              text: item.deposit_slip.date_of_deposit ,
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text: item.deposit_slip.bank_depositories.bank_code ,
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text: item.due_date ,
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text: item.loan_data.name  ,
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text: item.or_date  ,
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text: item.or_no  ,
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text:
                item.amount === 0
                  ? ''
                  : (item.amount / 1)
                    .toFixed(2)
                    .replace(',', '.')
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text:
                item.interest === 0
                  ? ''
                  : (item.interest / 1)
                    .toFixed(2)
                    .replace(',', '.')
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text:
                item.penalty === 0
                  ? ''
                  : (item.penalty / 1)
                    .toFixed(2)
                    .replace(',', '.')
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text:
                parseFloat(item.amount)+parseFloat(item.interest)+parseFloat(item.penalty)=== 0
                  ? ''
                  : (parseFloat(item.amount)+parseFloat(item.interest)+parseFloat(item.penalty) / 1)
                    .toFixed(2)
                    .replace(',', '.')
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              alignment: 'left',
              style: 'tableExample2',
            },

          ])
          if (Object.is(payment.length - 1, index)) {
            // execute last item logic
            payments_array.push([
              {
                text: '',
              },
              {
                text: '',
              },
              {
                text: '',
              },
              {
                text: '',
              },
              {
                text: '',
              },
              {
                text: 'TOTAL',
                alignment: 'right',
                style: 'tableExample2',
              },
              {
                text: (total_amm / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2',
              }, {
                text: (total_intt / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2',
              }, {
                text: (total_penal / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2',
              }, {
                text: (grand_total / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2',
              },
            ])
          }
        })

        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs
        }
        var docDefinition = {
          pageSize: 'LETTER',
          pageOrientation: 'landscape',
          content: [
            {
              columns: [
                {image: imgData, width: 60, height: 54, style: 'logo'},
                {
                  stack: [
                    'GOODLIFE GROUP OF COMPANIES',
                    {text: this.address, style: 'subheader2'},
                    {
                      text: this.contact,
                      style: 'subheader2',
                    },
                    {
                      text: 'Printed as of: ' + moment().format('MMMM D, YYYY'),
                      style: 'printed_label',
                    },
                  ],
                  style: 'header',
                },
                {
                  image:
                    this.position === 'ADMIN'
                      ? this.admin
                      : this.position === 'TREASURY'
                      ? this.trea
                      : this.position === 'ACCOUNTING'
                        ? this.accounting
                        : this.position === 'AMD'
                          ? this.amd
                          : this.position === 'PROPERTY CUSTODIAN'
                            ? this.prot
                            : this.position === 'HUMAN RESOURCE'
                              ? this.hr
                              : this.position === 'BOOKKEEPING'
                                ? this.book
                                : this.position === 'CMD'
                                  ? this.accounting
                                  : imgData,
                  width: 60,
                  height: 54,
                  style: 'logo',
                },
              ],
            },
            {
              stack: [
                {
                  columns: [
                    {
                      text: 'MONTHLY COLLECTION GGC LOANS',
                      style: {
                        fontSize: 16,
                        alignment: 'center',
                        bold: true,
                      },
                    },
                  ],
                },
              ],
            },
            '_________________________________________________________________________________________________________________________',
            {
              columns: [{text: ' '}],
            },
            {
              columns: [{text: 'Month Of: '+this.month_of},{text: 'Loan Type: '+this.type_of_loan}],
            },
            {
              stack: info_array,
            },
            {
              columns: [{text: ' '}],
            },
            {
              table: {
                widths: widths,
                body: payments_array,
                style: {fontSize: 1},
              },
              layout: {
                fillColor: function (rowIndex) {
                  return rowIndex === 0 ? '#CCCCCC' : null
                },
              },
            },
            {
              stack: [
                {
                  columns: [{text: ' '}],
                },
                {
                  columns: [{text: ' '}],
                },
                {
                  columns: [
                    {
                      text: 'PROCESSED BY',
                      style: 'main_data_2',
                    },
                    {
                      text: 'DEPARTMENT RECEIVER',
                      style: 'main_data_2',
                    },
                    {
                      text: 'RECEIVED BY',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: this.name,
                      style: 'main_data_2',
                    },
                    {
                      text: ' ',
                    },
                    {
                      text: '',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '_____________________',
                    },
                    {
                      text: '_____________________',
                    },
                    {
                      text: '_____________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: this.position,
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name',
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '',
                    },
                    {
                      text: ' ',
                    },
                    {
                      text: ' ',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'VERIFIED BY',
                      style: 'main_data_2',
                    },
                    {
                      text: 'AUDITED BY',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '_________________________________________',
                    },
                    {
                      text: '_________________________________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'BOARD OF DIRECTOR',
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name (TREASURY)',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: ' ',
                    },
                    {
                      text: ' ',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'APPROVED BY',
                      style: 'main_data_2',
                    },
                    {
                      text: '_________________________________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: ' ',
                    },
                    {
                      text: 'Signature Over Printed Name (ACCOUNTING)',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: ' ',
                    },
                    {
                      text: ' ',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'ALBERT M. HILARIO',
                      style: 'main_data_2',
                    },
                    {
                      text: '_________________________________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'EVP HEAD FINANCE',
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name (EXECUTIVE)',
                      style: 'main_data_2',
                    },
                  ],
                },
              ],
            },
          ],
          footer: function (currentPage, pageCount) {
            return {
              margin: 10,
              columns: [
                {
                  text: 'Page ' + currentPage.toString() + ' of ' + pageCount,
                  fontSize: 9,
                  alignment: 'left',
                },
                {image: damayan_, width: 34, height: 20, style: 'logo'},
                {image: chapel_, width: 34, height: 20, style: 'logo'},
                {image: factory_, width: 34, height: 20, style: 'logo'},
                {image: coop_, width: 34, height: 20, style: 'logo'},
                {image: printing_, width: 34, height: 20, style: 'logo'},
              ],
            }
          },
          styles: {
            tableExample: {
              fontSize: 9,
            },
            tableExample2: {
              fontSize: 7,
            },
            header: {
              fontSize: 21,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 5], //[left, top, right, bottom]
            },
            subheader: {
              fontSize: 11,
            },
            subheader2: {
              fontSize: 10,
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0], //[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 9,
              margin: [0, 0, 0, 0], //[left, top, right, bottom]
            },
            main_data: {
              margin: [0, 2, 0, 2],
              fontSize: 9,
              bold: true,
              alignment: 'left',
            },
            main_data_2: {
              margin: [0, 2, 0, 2],
              fontSize: 8,
              bold: true,
              alignment: 'left',
            },
            main_info: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
              alignment: 'left',
            },
          },
        }
        pdfMake.createPdf(docDefinition).open()
        this.printt = false
      },
    },
  }
</script>
